import React, { FC } from "react";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Link from "next/link";

import { ReactComponent as ArrowRight } from "@/assets/arrowRight.svg";
import { Typography, Box } from "@mui/material";

const classes = {
    menuList: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
    },
    listItemText: {

    },
    listItemTitle: {
        fontWeight: 600
    },
};


/***** TYPES *****/
interface LinkMenuProps {
    onClick?: React.MouseEventHandler;
    path: string;
    text?: string;
    icon?: JSX.Element;
    style?: object;
    arrow?: boolean;
    state?: any;
    target?: boolean;
}

/***** COMPONENT-FUNCTION *****/
const LinkMenu: FC<LinkMenuProps> = ({
    onClick,
    style,
    path,
    text,
    icon,
    arrow,
    state,
    target,
}): JSX.Element => {
    /*** Return statement ***/
    return (
        <>
            {path !== "" && (
                <Link
                    href={state ? { pathname: path, query: state } : path}
                    target={target ? "_blank" : ""}
                    style={classes.menuList}
                >
                    <MenuItem onClick={onClick}>
                        <ListItemText sx={classes.listItemText}>
                            <span style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                {icon} {text}
                            </span>
                            {arrow && <ArrowRight width={15} stroke="black" />}
                        </ListItemText>
                    </MenuItem>
                </Link>
            )}
            {path === "" && (                
                    <MenuItem onClick={onClick}>
                        <ListItemText>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', py: 1}}>
                                <Typography sx={classes.listItemTitle} variant="h5">{text} </Typography>
                                <Box>
                                    <ArrowRight width={15} stroke="black" />
                                </Box>
                            </Box>
                        </ListItemText>
                    </MenuItem>
            )}
        </>
    );
};

export default LinkMenu;
