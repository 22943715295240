import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as ROUTES from "@/constants/routes";
import runAgainLogo from "../../Images/runAgainAssets/RunAgain_Horizontal_Black.png";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import { ReactComponent as Home } from "@/assets/home.svg";
import { ReactComponent as Person } from "@/assets/person.svg";
import { ReactComponent as Bell } from "../../assets/bellBroken.svg";
import { ReactComponent as Search } from "@/assets/searchBroken.svg";
import AlertComponent from "../Alerts/AlertComponent";
import ProfileMenu from "./ProfileMenu";
import Image from "next/image";
import { withFirebase } from "../Firebase";
import {
    Badge,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Modal,
    SvgIcon,
} from "@mui/material";

const classes = {
    root: {
        position: "sticky",
        bottom: "0",
        left: "0",
        zIndex: 999,
        paddingTop: "0px",
        margin: "0px",
        borderRadius: "10px !important",
        "@media (min-width: 1200px)": {
            display: "none",
        },
    },
};

const LabelBottomNavigation = (props: any) => {
    const [value, setValue] = useState("recents");
    const [openProfile, setOpenProfile] = useState(false);
    const [openAlerts, setOpenAlerts] = useState(false);
    const [show, setShow] = useState(false);
    const [numberOfUnreadAlerts, setNumberOfUnreadAlerts] = useState(0);
    const { t } = useTranslation("translation");

    useEffect(() => {
        if (props.authUser?.alerts?.un_read?.length > 0) {
            setNumberOfUnreadAlerts(props.authUser?.alerts?.un_read?.filter((n: any) => n).length);
        }
    }, [props.authUser?.alerts]);

    const router = useRouter();

    const handleProfileOpen = () => {
        if (user) {
            setOpenProfile(true);
        } else {
            goToPage(ROUTES.SIGN_IN);
        }
    };

    const handleAlertsOpen = () => {
        if (user !== undefined) {
            setOpenAlerts(true);
        } else {
            goToPage(ROUTES.SIGN_IN);
        }
    };

    const handleProfileClose = () => {
        setOpenProfile(false);
    };

    const goToPage = (path: string) => {
        try {
            router.push(path);
        } catch {}
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    const handleAlertsClose = () => {
        setOpenAlerts(false);
    };

    const user = props.authUser;

    return (
        <Box sx={classes.root}>
            <BottomNavigation
                value={value}
                onChange={handleChange}
                showLabels
                sx={{ borderRadius: "10px 10px 0px 0px", height: "65px" }}
            >
                <BottomNavigationAction
                    value="Home"
                    label={t("components.navigation.home", null, { default: "" })}
                    onClick={() => goToPage(ROUTES.HOME)}
                    icon={<Home width={25} fill="#808080" />}
                    sx={{ "&:hover": { fill: "#ff8640" } }}
                />
                <BottomNavigationAction
                    value="SearchIcon"
                    label={t("components.navigation.findRun", null, { default: "" })}
                    onClick={() => goToPage(ROUTES.FIND_RUN)}
                    icon={<Search width={25} fill="#808080" />}
                    sx={{ "&:hover": { fill: "#ff8640" } }}
                />
                <BottomNavigationAction
                    value="Alerts"
                    label={t("components.navigation.alert", null, { default: "" })}
                    onClick={handleAlertsOpen}
                    icon={RenderAlertComponent(user, numberOfUnreadAlerts)}
                    sx={{ "&:hover": { color: "#ff86408" } }}
                />

                <BottomNavigationAction
                    value="Profile"
                    label={
                        user
                            ? t("components.navigation.profile", null, { default: "" })
                            : t("components.navigation.logIn", null, { default: "" })
                    }
                    icon={
                        user?.photoURL ? (
                            <Image
                                src={user?.photoURL}
                                height="30"
                                width="30"
                                quality="40"
                                style={{ borderRadius: "30px" }}
                                alt="ProfileImage"
                            />
                        ) : (
                            <Person width={25} fill="#808080" />
                        )
                    }
                    onClick={handleProfileOpen}
                    sx={{ "&:hover": { color: "#ff8640" } }}
                />
            </BottomNavigation>

            <Modal open={openProfile} disableScrollLock>
                <Box>
                    <ProfileMenu
                        open={openProfile}
                        handleDrawerClose={handleProfileClose}
                        authUser={user}
                        mobile={true}
                    />
                </Box>
            </Modal>

            {/* AlertsDialog */}
            <Dialog
                fullScreen={true}
                disableScrollLock
                open={openAlerts}
                onClose={handleAlertsClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <ArrowBackIosIcon onClick={handleAlertsClose} />
                        </Grid>
                        <Grid item>
                            <Image src={runAgainLogo} alt="runagain logo" />
                        </Grid>
                        <Grid item onClick={handleAlertsClose}>
                            <Search fontSize="large" onClick={() => goToPage(ROUTES.FIND_RUN)} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <>
                        <AlertComponent
                            user={user}
                            mobileView={true}
                            close={handleAlertsClose}
                            readAlert={() => setNumberOfUnreadAlerts(numberOfUnreadAlerts - 1)}
                            readAllAlerts={() => setNumberOfUnreadAlerts(0)}
                        />
                    </>
                </DialogContent>
            </Dialog>
            {/* {show ? <SignInPage onClose={() => setShow(false)} /> : null} */}
        </Box>
    );
};

const RenderAlertComponent = (user: any, numberOfUnreadAlerts: number) => {
    return (
        <>
            {user?.id !== null && (
                <Badge badgeContent={numberOfUnreadAlerts} color="primary">
                    <Bell fill="#808080" width={25} />
                </Badge>
            )}
            {user?.id === null && <Bell fill="#808080" width={25} />}
        </>
    );
};

export default withFirebase(LabelBottomNavigation);
